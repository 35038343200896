/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
// @tailwind base
// @tailwind components
// @tailwind utilities
// @tailwind variants
    
*
    @apply box-border
    -webkit-tap-highlight-color: transparent
    -webkit-touch-callout: none

html, body
    @apply max-h-screen overflow-hidden
    @apply text-slate-800


img, p, span, h1, h2, h3, a, button
    @apply select-none